import React, { useState } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function About() {
  const initialBoxesState = [
    {
      isOpen: false,
      title: 'Who Am I',
      text: `
    👋 there, I'm Nasta(sios), currently living in my hometown of
    Nuremberg, Germany.
    I'm a creative and innovative force, specializing in frontend
    wizardry while boasting a solid command of the backend realm as
    well.
    Fluent in German and Greek and rock-solid in English I navigate
    the digital landscape with a multilingual flair.
    `,
    },
    {
      isOpen: false,
      title: 'Hobbies',
      text: `
        Beyond coding, I've got a bunch of hobbies. I'm into ⚽️, both
        playing and cheering for my favorite teams. Riding my bike is a
        great way for me to relax and enjoy some fresh air while listening
        to some good music. I'm also big on reading, especially books that
        dive into creativity.
        Whether with friends or flying solo, I love to travel and explore
        new places. When the ☀️'s out, you'll likely find me outside,
        soaking up the warmth.`,
    },
    {
      isOpen: false,
      title: 'Studies',
      text: `
        I pursued my studies in web development at SRH Berlin University
        of Applied Sciences.
        There, I delved into the fundamentals of web development, honed
        skills in web/graphic design, as well as UX/UI Design and even
        dipped my toes into the world of game development.
    `,
    },
    {
      isOpen: false,
      title: 'Jobs',
      text: `
        I gained valuable experience through a 6-month web development
        internship at Init AG in Berlin, primarily focusing on frontend
        tasks.
        Following that, probably the proudest moment for my parents, I
        served as a software engineer for Red Bull Media House for a year,
        contributing to both frontend and backend development.
    `,
    },
    {
      isOpen: false,
      title: 'Freelancing',
      text: `
        Juggling studies and waiting tables at a local spot, I also dived
        into the freelance hustle.
        Crafting slick websites from the ground up or giving old ones a
        badass makeover, I brought digital dreams to life for my clients.
        It was my way of injecting a dose of cool into the world of web
        development while rocking the service industry.
    `,
    },
    {
      isOpen: false,
      title: 'Why Outerweb',
      text: `
        To me, the term transcends the traditional boundaries of web
        development. It signifies venturing beyond the ordinary, exploring
        the outer realms of creativity and innovation.
        In this digital universe, Outerweb is not just about crafting
        websites; it's about pushing the boundaries and bringing a touch
        of the extraordinary to the online landscape.
    `,
    },
    {
      isOpen: false,
      title: 'Skills',
      text: `
        React.js, SASS, TailwindCSS, GreenSock, JavaScript,
        Node/Express.js, REST, MongoDB
    `,
    },
  ];

  const [boxes, setBoxes] = useState(initialBoxesState);

  const toggleText = (index) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].isOpen = !updatedBoxes[index].isOpen;
    setBoxes(updatedBoxes);
  };

  useGSAP(() => {
    const boxes = gsap.utils.toArray('.box');

    gsap.to(boxes, {
      scrollTrigger: {
        trigger: '.landing-page',
        start: 'bottom center',
        toggleAction: 'start none none reverse',
      },
      scale: 1,
      ease: 'power',
      stagger: 0.2,
    });
  });

  return (
    <section className="about">
      <div className="container">
        <div className="inner-container">
          <div className="box topic-box">About Me</div>
          {boxes.map((box, index) => (
            <div className="box" key={index}>
              <div className="toggle-box">
                <h2
                  className={`plus ${box.isOpen ? 'open' : ''}`}
                  onClick={() => toggleText(index)}
                >
                  {box.title} {box.isOpen ? '-' : '+'}
                </h2>
              </div>
              <div
                style={{
                  height: !box.isOpen ? '0' : 'auto',
                  overflow: 'hidden',
                  opacity: !box.isOpen ? '0' : '1',
                  transition: 'opacity .5s ease-in, height .5s ease-in',
                  visibility: !box.isOpen ? 'hidden' : 'visible',
                }}
                className="toggle-text hide"
              >
                {box.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
