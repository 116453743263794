import LatestProject from '../public/img/portfolio/LatestProject.webp';
import CurrentProject from '../public/img/portfolio/CurrentProject.webp';
import HobbyProject from '../public/img/portfolio/HobbyProject.webp';
import rocket from '../public/img/portfolio/rocket.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);
function Portfolio() {
  const [slider, setSlider] = useState(null);
  useEffect(() => {
    if (slider) {
      const sections = gsap.utils.toArray('.slider .section');
      const rockets = gsap.utils.toArray('.rocket-container img');
      const contents = gsap.utils.toArray('.inner .content');

      let portfolioTl = gsap.timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: slider,
          pin: true,
          scrub: 2,
          end: () => '+=' + slider.offsetWidth,
        },
      });

      portfolioTl.to(
        slider,
        {
          xPercent: -66.6666,
        },
        '<'
      );
      if (sections[1]) {
        portfolioTl.to(sections[1], {
          backgroundColor: '#202020',
          scrollTrigger: {
            trigger: sections[0],
            start: 'right 40',
            end: 'right',
            scrub: true,
          },
        });
      }
      // Change font color Project 2
      portfolioTl.to(
        [
          contents[1].querySelector('h1'),
          contents[1].querySelector('p'),
          contents[1].querySelector('.tech-stack'),
          contents[1].querySelectorAll('.project-links a'),
        ],
        {
          color: '#8ED081',
          scrollTrigger: {
            trigger: sections[2],
            start: 'right',
            end: 'right 50',
            scrub: true,
          },
        }
      );
      // Change font color Project 3
      portfolioTl.to(
        [
          contents[2].querySelector('h1'),
          contents[2].querySelector('p'),
          contents[2].querySelector('.tech-stack'),
          contents[2].querySelectorAll('.project-links a'),
        ],
        {
          color: '#1c1d21',
          scrollTrigger: {
            trigger: sections[3],
            start: 'left',
            end: 'right 100',
            scrub: true,
          },
        }
      );

      // Change bg project 3
      portfolioTl.to(sections[2], {
        backgroundColor: '#DCF8DC',
        scrollTrigger: {
          trigger: sections[2],
          end: 'right -50',
          scrub: true,
        },
      });

      sections.forEach((stop, index) => {
        // portfolioTl.to(
        //   sections,
        //   {
        //     xPercent: -(100 * index),
        //     duration: index,
        //     scrollTrigger: {
        //       // trigger: sections,
        //       snap: 1 / (sections.length - 1),
        //     },
        //   },
        //   '<'
        // );
        portfolioTl
          .from(stop.querySelector('.content'), {
            yPercent: -400,
            opacity: 0,
            scrollTrigger: {
              trigger: stop.querySelector('img'),
              end: 'left 50%',
              containerAnimation: portfolioTl,
              scrub: true,
            },
          })
          .from(
            stop.querySelector('img'),
            {
              xPercent: 40,
              yPercent: -100,
              ease: 'elastic.out(1, 1)',
              scrollTrigger: {
                trigger: stop.querySelector('.content'),
                scrub: 2,
                containerAnimation: portfolioTl,
              },
            },
            '<'
          );
      });

      // Rockets animation
      rockets.forEach((rocket, i) => {
        gsap.from(rocket, {
          xPercent: rocket.dataset.distance,
          scrollTrigger: {
            scrub: 0.3,
          },
        });
      });
    }
  });

  return (
    <>
      <div className="outer">
        <div className="slider" ref={(ref) => setSlider(ref)}>
          <section className="portfolio section">
            <div className="inner">
              <img src={LatestProject} alt="Current Project" />
              <div className="content">
                <h1>Latest Project</h1>
                <p className="project-description">
                  Designed and developed the website for Restaurant Delphi, a
                  Greek restaurant based in Vienna. The website combines modern,
                  clean design with traditional elements. With intuitive
                  navigation and a responsive layout, visitors can seamlessly
                  explore the menu. It was a collaborative effort to bring the
                  warmth of Greek hospitality online, inviting guests to
                  experience the flavors of Greece at restaurant Delphi.
                </p>
                <p className="tech-stack">
                  React.js - React-Router-Dom - SASS - GSAP - Figma
                </p>
                <div className="project-links">
                  <a href="https://delphi-hietzing.at/" target="_blank" rel="noreferrer">Link</a>
                  {/* <a href="/">GitHub</a> */}
                </div>
              </div>
            </div>
          </section>
          <section className="section-project-2 section">
            <div className="inner">
              <img src={CurrentProject} alt="Current Project" />
              <div className="content">
                <h1>Current Project</h1>
                <p className="project-description">
                  Creating a sleek website design with a captivating dark theme
                  for a local magician, ensuring seamless navigation through
                  their repertoire. Additionally, managing Google Analytics
                  integration for enhanced SEO performance, optimizing their
                  online presence effectively.
                </p>
                <p className="tech-stack">
                  HTML - SASS - GSAP - Figma - Google Analytics
                </p>
                <div className="project-links">
                  {/* <a href="/">Link</a> */}
                  <a href="/">GitHub</a>
                </div>
              </div>
            </div>
          </section>
          <section className="portfolio section">
            <div className="inner">
              <img src={HobbyProject} alt="Current Project" />
              <div className="content">
                <h1>Hobby Project</h1>
                <p className="project-description">
                  A personal music player project that I am working on. Goal of
                  the project is, to build an algorithm for filtering the genres
                  of the songs in the playlists. Currently you can register,
                  sign-in, upload new albums and new songs.
                </p>
                <p className="tech-stack">
                  React.js - React-Router-Dom - TailwindCSS - Node.js/Express.js
                  - Mongoose - JWT - Bcryptjs
                </p>
                <div className="project-links">
                  {/* <a href="/">Link</a> */}
                  <a href="https://github.com/nastasiosg/music-player" target="_blank" rel="noreferrer">GitHub</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="rocket-container">
        <img src={rocket} data-distance="180" alt="Animated Rocket" />
        <img src={rocket} data-distance="260" alt="Animated Rocket" />
        <img src={rocket} data-distance="400" alt="Animated Rocket" />
        <img src={rocket} data-distance="220" alt="Animated Rocket" />
        <img src={rocket} data-distance="340" alt="Animated Rocket" />
        <img src={rocket} data-distance="310" alt="Animated Rocket" />
        <img src={rocket} data-distance="200" alt="Animated Rocket" />
        <img src={rocket} data-distance="395" alt="Animated Rocket" />
      </div>
    </>
  );
}

export default Portfolio;
