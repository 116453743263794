import { ReactLenis } from '@studio-freight/react-lenis';
import MainPage from './pages/MainPage';

function App() {
  return (
    <ReactLenis root>
      <MainPage />
    </ReactLenis>
  );
}

export default App;
