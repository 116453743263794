import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// img
import bg from '../public/img/landing/space.webp';
import earth from '../public/img/landing/earth.webp';
import darkSpace from '../public/img/landing/dark.webp';
import flames from '../public/img/landing/flames.webp';
import mask from '../public/img/landing/mask.webp';

gsap.registerPlugin(ScrollTrigger);

function Landing() {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  useEffect(() => {
    let img = new Image();
    img.src = darkSpace;
  }, [imageIsLoaded]);

  useGSAP(() => {
    const images = gsap.utils.toArray('.parallax');

    images.forEach((image) => {
      gsap.to(image, {
        yPercent: image.dataset.speed,
        scrollTrigger: {
          scrub: true,
        },
      });
    });
  });

  return (
    <>
      <img src={mask} className="mask" alt="Mask Overlay" />

      <section className="landing-page">
        {/* 1250 */}
        <h1 className="parallax" data-speed="1650">
          Outerweb
        </h1>
        <img
          src={bg}
          alt="Background Space"
          className="bg parallax"
          data-speed="0"
        />
        <img
          src={darkSpace}
          onLoad={() => setImageIsLoaded(true)}
          alt="Dark Space"
          className="dark-space parallax"
          data-speed="10"
        />
        {/* -100 */}
        <img
          src={earth}
          alt="Earth"
          className="earth parallax"
          data-speed="-150"
        />
        <img
          src={flames}
          alt="Flames in Space"
          className="flames parallax"
          data-speed="20"
        />
      </section>
    </>
  );
}

export default Landing;
