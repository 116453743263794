import Landing from '../components/Landing';
import About from '../components/About';
import Portfolio from '../components/Portfolio';
import Findme from '../components/Findme';

import '../App.scss';

function MainPage() {
  return (
    <>
      <div className="show-layout">
        <Landing />
        <About />
        <Portfolio />
        <Findme />
      </div>
      <div className="hide-layout">Please open page on a bigger screen</div>
    </>
  );
}

export default MainPage;
